import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Button, CircularProgress, Dialog, DialogContent, Slide, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Proptypes from 'prop-types'

import agent from 'agent'
import TenopyBrand from 'assets/tenopy-brand.svg'
import { CONTACT_US } from 'constants/actionTypes'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  contactInProgress: state.misc.contactInProgress
})

const mapDispatchToProps = dispatch => ({
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) })
})

class GetInTouch extends React.Component {
  state = {
    errorMsg: '',
    successMsg: '',
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    sent: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState({
        errorMsg: '',
        successMsg: '',
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        sent: false
      })
    }

    if (prevProps.contactInProgress && !this.props.contactInProgress) {
      this.setState({ successMsg: 'Thanks for contacting us. We will reach you soon.' })
    }
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value, errorMsg: '' })
  }

  contactTenopy = () => {
    const { intent } = this.props
    const { name, email, phoneNumber, message } = this.state
    if (name && email) {
      this.props.contactUs({
        message_type: 'contact',
        message: `${name} (${email}) (${phoneNumber ?? 'N/A'}) contacted with message: ${message ?? 'N/A'}`,
        intent
      })
      this.setState({ sent: true })
    } else {
      this.setState({
        errorMsg: 'Please provide at least your name and email.'
      })
    }
  }

  render() {
    let { isOpen, contactInProgress } = this.props
    let { name, email, phoneNumber, message, errorMsg, successMsg, sent } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="md">
        <DialogContent>
          <div className="row align-items-center" style={{ overflow: 'hidden' }}>
            {!isMobile && (
              <div className="col-md-5 col-12">
                <img src={TenopyBrand} height="60" alt="tenopy logo" className="my-3" />
                <div className="d-flex flex-row mb-3">
                  <i className="material-icons mr-2">phone</i>
                  <span>+65 9469 6793</span>
                </div>
                <div className="d-flex flex-row mb-3">
                  <i className="material-icons mr-2">email</i>
                  <span>ask@tenopy.com</span>
                </div>
                <div className="d-flex flex-row mb-3">
                  <i className="material-icons mr-2">business</i>
                  <span>79 Ayer Rajah Crescent, #02-08, Singapore 139955</span>
                </div>
              </div>
            )}
            <div className="col-md-7 col-12 px-4 py-3">
              <div className="d-flex flex-row justify-content-between">
                <h2 className="mb-1">Say Hello!</h2>
                <i className="material-icons clickable" onClick={this.props.close}>
                  close
                </i>
              </div>

              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              {successMsg && <Alert severity="success">{successMsg}</Alert>}

              <TextField
                type="text"
                label="Name"
                name="name"
                onChange={this.onChangeFormElement}
                fullWidth
                value={name}
                margin="normal"
                variant="outlined"
              />

              <TextField
                type="text"
                label="Email"
                name="email"
                onChange={this.onChangeFormElement}
                fullWidth
                value={email}
                margin="normal"
                variant="outlined"
              />

              <TextField
                type="text"
                label="Phone Number (optional)"
                name="phoneNumber"
                onChange={this.onChangeFormElement}
                fullWidth
                value={phoneNumber}
                margin="normal"
                variant="outlined"
              />

              <TextField
                type="text"
                label="Message"
                name="message"
                onChange={this.onChangeFormElement}
                fullWidth
                value={message}
                margin="normal"
                variant="outlined"
                multiline
                rows="5"
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={this.contactTenopy}
                disabled={sent}
                fullWidth={true}
                className="my-2">
                Send {contactInProgress && <CircularProgress size={24} className="ml-2" />}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

GetInTouch.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetInTouch))
